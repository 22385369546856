import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from 'Screens/Home';
import About from 'Screens/About';
import Thanks from 'Screens/Thanks';
import ProjectDetails from 'Screens/ProjectDetails';

export const ROUTES = {
  ABOUT: '/about',
  HOME: '/',
  PROJECT_DETAIL: '/project-detail',
  THANK_YOU: '/thank-you',
};

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path={`${ROUTES.PROJECT_DETAIL}/:name`}>
          <ProjectDetails />
        </Route>
        <Route path={ROUTES.ABOUT}>
          <About />
        </Route>
        <Route path={ROUTES.THANK_YOU}>
          <Thanks />
        </Route>
        <Route path={ROUTES.HOME}>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
