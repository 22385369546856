import React from 'react';
import styles from './ProjectDetailsHeader.module.scss';
import { motion } from 'framer-motion';
import ProjectDetailNav from '../ProjectDetailsNav';
import AnimationWrapper from '../AnimationWrapper';
import { fadeInUp, stagger } from '../../../Animations';

export default function ProjectDetailsHeader({
  cover,
  logo,
  quote,
  text,
  title,
  color,
}) {
  return (
    <AnimationWrapper variants={stagger}>
      <div
        className={styles.projectDetailsHeader}
        style={{ backgroundColor: color }}
      >
        <ProjectDetailNav />
        <motion.div
          variants={fadeInUp}
          className={styles.projectDetailsHeader__wrapper__logo}
        >
          <img
            className={styles.projectDetailsHeader__logo}
            src={logo}
            alt=""
          />
        </motion.div>
        <motion.h2
          variants={fadeInUp}
          className={styles.projectDetailsHeader__quote}
        >
          {quote}
        </motion.h2>
        <div className={styles.projectDetailsHeader__wrapper__cover}>
          <motion.img
            variants={fadeInUp}
            className={styles.projectDetailsHeader__cover}
            src={cover}
            alt=""
          />
        </div>
        <motion.div
          variants={fadeInUp}
          className={styles.projectDetailsHeader__description}
        >
          <h3 className={styles.projectDetailsHeader__description__title}>
            {title}
          </h3>
          <p className={styles.projectDetailsHeader__description__text}>
            {text}
          </p>
        </motion.div>
      </div>
    </AnimationWrapper>
  );
}
