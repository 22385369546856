import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { LeftButton, RightButton } from './CarouselNextButton';
import styles from './ProjectDetailCarousel.module.scss';

export default function ProjectDetailCarousel({ images }) {
  return (
    <Carousel
      renderArrowPrev={(clickHandler, hasPrev, label) => (
        <LeftButton
          clickHandler={clickHandler}
          hasPrev={hasPrev}
          label={label}
        />
      )}
      renderArrowNext={(clickHandler, hasNext, label) => (
        <RightButton
          clickHandler={clickHandler}
          hasNext={hasNext}
          label={label}
        />
      )}
      centerMode={true}
      showThumbs={false}
      swipeable={true}
      showStatus={false}
      emulateTouch={true}
      className={styles.projectDetailCarousel}
    >
      {images.map((slide, index) => (
        <div key={index} className={styles.projectDetailCarousel__slide}>
          <img
            className={styles.projectDetailCarousel__slide__image}
            src={slide.url}
            alt=""
          />
        </div>
      ))}
    </Carousel>
  );
}
