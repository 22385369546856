import { useState } from 'react';
import * as yup from 'yup';
import ContactTL from 'Translations/contact.json';
import { useSelectContext } from 'Context/SelectLanguageContext';
import { ContactFormValidationError } from 'Errors/validations';
const useContactConnectors = () => {
  const { t } = useSelectContext();

  const phoneRegExp = /^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/;

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [companyName, setCompanyName] = useState();
  const [budget, setBudget] = useState();
  const [projectDescription, setProjectDescription] = useState();

  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [companyNameError, setCompanyNameError] = useState();
  const [budgetError, setBudgetError] = useState();
  const [projectDescriptionError, setProjectDescriptionError] = useState();

  const [isLoading, setLoading] = useState();
  const [formError, setFormError] = useState(); // show user that there is a form error

  const onChangeFirstName = (input) => {
    setFirstNameError('');
    setFirstName(input);
  };
  const onChangeLastName = (input) => {
    setLastNameError('');
    setLastName(input);
  };
  const onChangeEmail = (input) => {
    setEmailError('');
    setEmail(input);
  };
  const onChangePhoneNumber = (input) => {
    setPhoneNumberError('');
    setPhoneNumber(input);
  };
  const onChangeCompanyName = (input) => {
    setCompanyNameError('');
    setCompanyName(input);
  };
  const onChangeBudget = (input) => {
    setBudgetError('');
    setBudget(input);
  };
  const onChangeProjectDescription = (input) => {
    setProjectDescriptionError('');
    setProjectDescription(input);
  };

  const contactFormSchema = yup.object().shape({
    name: yup.string().required(t(ContactTL.nameRequiredErrorMessage)),
    lastname: yup.string().required(t(ContactTL.lastNameRequiredErrorMessage)),
    email: yup
      .string()
      .email()
      .required(t(ContactTL.emailRequiredErrorMessage))
      .typeError(t(ContactTL.emailFormatErrorMessage)),
    phoneNumber: yup
      .string()
      .required(t(ContactTL.phoneRequiredErrorMessage))
      .matches(phoneRegExp, t(ContactTL.phoneFormatErrorMessage)),
    companyName: yup.string(),
    budget: yup.string(),
    description: yup.string(),
  });

  const keySetErrorMap = {
    name: setFirstNameError,
    lastname: setLastNameError,
    email: setEmailError,
    phoneNumber: setPhoneNumberError,
    companyName: setCompanyNameError,
    budget: setBudgetError,
    description: setProjectDescriptionError,
  };

  const formValidation = async () => {
    try {
      loading(true);
      setFormError('');
      await contactFormSchema.validate(
        {
          name: firstName,
          lastname: lastName,
          email: email,
          phoneNumber: phoneNumber,
          companyName: companyName,
          budget: budget,
          description: projectDescription,
        },
        { abortEarly: false }
      );
    } catch (err) {
      err.inner.forEach((yupError) => {
        // yupError {{path: the key like name or email, error: the custom error message defined on the schema }}
        keySetErrorMap[yupError.path](yupError.message);
      });
      setFormError(t(ContactTL.submitError));
      throw new ContactFormValidationError();
    }
  };

  const loading = (value) => {
    setLoading(value);
  };

  return {
    values: {
      firstName,
      lastName,
      email,
      phoneNumber,
      companyName,
      budget,
      projectDescription,
      firstNameError,
      lastNameError,
      emailError,
      phoneNumberError,
      companyNameError,
      budgetError,
      projectDescriptionError,
      formError,
      isLoading,
    },
    onChangeMethods: {
      onChangeFirstName,
      onChangeLastName,
      onChangeEmail,
      onChangePhoneNumber,
      onChangeCompanyName,
      onChangeBudget,
      onChangeProjectDescription,
    },
    formValidation,
    loading,
  };
};

export default useContactConnectors;
