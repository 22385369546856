import React from 'react';
import styles from './ProjectDetailsNav.module.scss';
import projectTl from 'Translations/project.json';
import BackLink from 'Common/Components/BackLink';
import { useSelectContext } from 'Context/SelectLanguageContext';
import Logo from '../Logo';
import Menu from 'Common/Components/Menu';

export default function ProjectDetailNav() {
  const { t } = useSelectContext();

  return (
    <div className={styles.projectDetailsNav}>
      <div className={styles.projectDetailsNav__wrapper__logo}>
        <Logo />
      </div>
      <BackLink title={t(projectTl.HomePage)} />
      <Menu showLinks={false} />
    </div>
  );
}
