import React from 'react';
import styles from './Numbers.module.scss';
import Number from './Number';
import SectionTitle from '../SectionTitle';
import ProjectTl from 'Translations/project.json';
import { useSelectContext } from 'Context/SelectLanguageContext';

export default function Numbers(props) {
  let { t } = useSelectContext();

  return (
    <div className={styles.projectDetails__numbers}>
      <SectionTitle text={t(ProjectTl.TheNumbers)} />
      <div className={styles.projectDetails__numbers__innerWrapper}>
        {props.stats.map(({ digit, text }, index) => (
          <Number key={index} digit={digit} subText={text} />
        ))}
      </div>
    </div>
  );
}
