import emailjs from 'emailjs-com';

const sendEmail = async (messageBody) => {
  await emailjs.send(
    process.env.REACT_APP_MAILER_SERVICE_ID,
    process.env.REACT_APP_MAILER_TEMPLATE_ID,
    messageBody,
    process.env.REACT_APP_MAILER_USER_ID
  );
};

export default sendEmail;
