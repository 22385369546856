import React from 'react';
import { Link } from 'react-router-dom';
import rightArrow from 'Assets/right-arrow.png';
import styles from './BackLink.module.scss';
export default function index(props) {
  return (
    <Link className={styles.projectDetailsNav__backLink} to={'/'}>
      <img
        className={styles.projectDetailsNav__backLink__logo}
        src={rightArrow}
        alt=""
      />
      <span className={styles.projectDetailsNav__backLink__text}>
        {props.title}
      </span>
    </Link>
  );
}
