import { RadioGroup, ReversedRadioButton } from 'react-radio-buttons';
import React from 'react';
import Input from './Input';
import styles from './Contact.module.scss';
import ContactTL from 'Translations/contact.json';
import Button from 'Common/Components/Button';
import { useSelectContext } from 'Context/SelectLanguageContext';

export default function Contact({
  firstName,
  lastName,
  email,
  phoneNumber,
  companyName,
  budget,
  isLoading,
  projectDescription,
  onChangeFirstName,
  onChangeLastName,
  onChangeEmail,
  onChangePhoneNumber,
  onChangeCompanyName,
  onChangeBudget,
  onChangeProjectDescription,
  firstNameError,
  lastNameError,
  emailError,
  phoneNumberError,
  companyNameError,
  budgetError,
  projectDescriptionError,
  onSendForm,
  formError,
  formValid,
  propsRef,
}) {
  const { t } = useSelectContext();

  return (
    <div ref={propsRef} className={styles.container}>
      {ContactTL && (
        <div className={styles.inside_container}>
          <h1 className={styles.contact_header}>{t(ContactTL.ContactUs)}</h1>
          <div className={styles.input_row}>
            <Input
              value={firstName}
              error={firstNameError}
              onChange={(value) => onChangeFirstName(value)}
              placeholder={t(ContactTL.FirstName)}
              type={'text'}
            />
            <Input
              value={lastName}
              error={lastNameError}
              onChange={(value) => onChangeLastName(value)}
              placeholder={t(ContactTL.LastName)}
              type={'text'}
            />
          </div>
          <div className={styles.one_row_item_container}>
            <Input
              value={email}
              error={emailError}
              onChange={(value) => onChangeEmail(value)}
              placeholder={t(ContactTL.Email)}
              type={'email'}
            />
          </div>
          <div className={styles.one_row_item_container}>
            <Input
              value={phoneNumber}
              error={phoneNumberError}
              onChange={(value) => onChangePhoneNumber(value)}
              placeholder={t(ContactTL.PhoneNumber)}
              type={'phone'}
            />
          </div>
          <div className={styles.one_row_item_container}>
            <Input
              value={companyName}
              error={companyNameError}
              onChange={(value) => onChangeCompanyName(value)}
              placeholder={t(ContactTL.Company)}
              type={'text'}
            />
          </div>
          <h2 className={styles.budget_header}>
            {t(ContactTL.ExpectedBudget)}
          </h2>
          <div className={styles.one_row_item_container}>
            <RadioGroup
              value={budget}
              error={budgetError}
              onChange={onChangeBudget}
            >
              <ReversedRadioButton
                iconSize={20}
                pointColor="#FFB600"
                value="€3000 -  €15 000"
              >
                <p className={styles.option_text}>€3000 - €15 000</p>
              </ReversedRadioButton>
              <ReversedRadioButton
                iconSize={20}
                pointColor="#FFB600"
                value="€15000 -  €50 000"
              >
                <p className={styles.option_text}>€15000 - €50 000</p>
              </ReversedRadioButton>
              <ReversedRadioButton
                iconSize={20}
                pointColor="#FFB600"
                value="€50000 -  €100 000"
              >
                <p className={styles.option_text}>€50000 - €100 000</p>
              </ReversedRadioButton>
              <ReversedRadioButton
                iconSize={20}
                pointColor="#FFB600"
                value="€100 000 -  €150 000"
              >
                <p className={styles.option_text}>€100 000 - €150 000</p>
              </ReversedRadioButton>
            </RadioGroup>
          </div>
          <h2 className={styles.budget_header}>
            {t(ContactTL.DescribeYourProject)}
          </h2>
          <div className={styles.one_row_item_container}>
            <textarea
              value={projectDescription}
              error={projectDescriptionError}
              onChange={(event) =>
                onChangeProjectDescription(event.target.value)
              }
              className={styles.contact_textarea}
              placeholder={t(ContactTL.YourProjectDescription)}
              row={10}
            />
          </div>
          <div className={styles.one_row_item_container}>
            <Button
              loading={isLoading}
              onClick={() => onSendForm()}
              label={t(ContactTL.Send)}
            />
            <p className={`${styles.contact_submit_title} ${styles.error}`}>
              {formError}
            </p>
            <p className={`${styles.contact_submit_title} ${styles.success}`}>
              {formValid}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
