import React from 'react';
import Logo from '../Logo';
import SF from 'Assets/SF.png';
import StartLab from 'Assets/StartLab.png';
import aboutTl from 'Translations/about.json';
import Menu from 'Common/Components/Menu';
import { motion } from 'framer-motion';
import { useSelectContext } from 'Context/SelectLanguageContext';
import styles from './HeaderAbout.module.scss';
import { fadeInUp, stagger } from '../../../Animations';
import AnimationWrapper from '../AnimationWrapper';

export default function HeaderAbout(props) {
  const { t } = useSelectContext();

  return (
    <AnimationWrapper variants={stagger}>
      <div className={styles.wrapper}>
        {aboutTl && (
          <div>
            <div className={styles.ozLogo_wrapper}>
              <Logo />
            </div>
            <Menu />
            <motion.div variants={fadeInUp} className={styles.container}>
              <div className={styles.yellow_title_wrapper}>
                <h1 className={styles.title}>{t(aboutTl.Work)}</h1>
                <h1 className={`${styles.title} ${styles.yellow}`}>
                  {t(aboutTl.Methods)}
                </h1>
                <h1 className={styles.title}>{t(aboutTl.And)}</h1>
              </div>
              <br />
              <div className={styles.yellow_title_wrapper}>
                <h1 className={styles.title}>{t(aboutTl.Development)}</h1>
                <h1 className={`${styles.title} ${styles.yellow}`}>
                  {t(aboutTl.Tools)}
                </h1>
              </div>
              <p className={styles.about_header_paragraph}>
                {t(aboutTl.OurDevelopmentAndDesign)}
              </p>
              <div onClick={props.onButtonPress} className={styles.button}>
                <p className={styles.button_label}>
                  {t(aboutTl.LetsStartAProject)}
                </p>
              </div>
            </motion.div>

            <div className={styles.incubators}>
              <div className={styles.separator}></div>
              <div className={styles.incubators_section}>
                <motion.p
                  variants={fadeInUp}
                  className={styles.incubators_label}
                >
                  {t(aboutTl.IncubatorsWeHaveWorked)}
                </motion.p>
                <div className={styles.logo_wrapper}>
                  <motion.img
                    variants={fadeInUp}
                    className={styles.incubator_logo}
                    src={StartLab}
                    alt="startlab logo"
                  />
                  <motion.img
                    variants={fadeInUp}
                    className={styles.incubator_logo}
                    src={SF}
                    alt="SF logo"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AnimationWrapper>
  );
}
