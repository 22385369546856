import React from 'react';
import BeehireLogo from '../../../Assets/BeehireLogo.png';
import ChecklyLogo from '../../../Assets/ChecklyLogo.png';
import CheckHubLogo from '../../../Assets/CheckHubLogo.png';
import HHMLogo from '../../../Assets/HHMLogo.png';
import SamapassLogo from '../../../Assets/SamapassLogo.png';
import MinWidthRender from '../MinWidthRender';
import styles from './HeaderProjectList.module.scss';

export default function HeaderProjectList() {
  return (
    <div className={styles.container}>
      <MinWidthRender minWidth={900}>
        <img className={styles.logo} src={BeehireLogo} alt="BeehireLogo" />
      </MinWidthRender>
      <MinWidthRender minWidth={800}>
        <img className={styles.logo} src={HHMLogo} alt="HHMLogo" />
      </MinWidthRender>

      <MinWidthRender minWidth={700}>
        <img className={styles.logo} src={CheckHubLogo} alt="CheckHubLogo" />
      </MinWidthRender>

      <MinWidthRender minWidth={600}>
        <img className={styles.logo} src={SamapassLogo} alt="SamapassLogo" />
      </MinWidthRender>

      <MinWidthRender minWidth={100}>
        <img className={styles.logo} src={ChecklyLogo} alt="ChecklyLogo" />
      </MinWidthRender>
    </div>
  );
}
