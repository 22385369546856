import React from 'react';
import { motion } from 'framer-motion';
import styles from './QnASectionDescription.module.scss';

export default function QnASectionDescription({
  title,
  description,
  variants,
}) {
  return (
    <motion.div variants={variants} className={styles.QnASection}>
      <p className={styles.QnASection__title}>{title}</p>
      <div className={styles.QnASection__description}>{description}</div>
    </motion.div>
  );
}
