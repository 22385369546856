import languageDictionary from 'Config/languageDictionary';
import { useEffect, useState } from 'react';

export const useSelectLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const languages = Object.keys(languageDictionary);

  const getBrowserLanguage = () => {
    let browserLanguage = navigator.language || navigator.userLanguage;
    let wellFormattedLanguage = browserLanguage.slice(0, 2);
    return languageDictionary[wellFormattedLanguage];
  };

  const translate = (projectKey) =>
    projectKey.find((valueKey) => valueKey.locale === currentLanguage).value;

  useEffect(() => {
    let defaultLanguage = currentLanguage || getBrowserLanguage();
    setCurrentLanguage(defaultLanguage);
  }, [currentLanguage]);

  return { t: translate, setCurrentLanguage, currentLanguage, languages };
};
