import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function AnimationWrapper({ children, variants }) {
  const animation = useAnimation();

  const [contentRef, inView] = useInView({
    rootMargin: document.body.clientHeight / 16 + 'px',
  });

  useEffect(() => {
    if (inView) {
      animation.start('animate');
    }
  }, [animation, inView]);

  return (
    <motion.div
      ref={contentRef}
      animate={animation}
      initial={'initial'}
      variants={variants}
    >
      {children}
    </motion.div>
  );
}
