import React from 'react';
import styles from './SectionTitle.module.scss';

export default function SectionTitle({ text, marginTop }) {
  return (
    <div style={{ marginTop: marginTop || 0 }} className={styles.sectionTitle}>
      <h3 className={styles.sectionTitle__text}>{text}</h3>
      <hr className={styles.sectionTitle__bottomBar} />
    </div>
  );
}
