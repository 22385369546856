import React from 'react';
import { useHistory } from 'react-router-dom';
import sendEmail from 'Gateways/email';
import useContactConnectors from 'Hooks/useContactConnectors';
import { validationErrorsType } from 'Errors/validations';
import { ROUTES } from 'Common/Router';
import Contact from 'Common/Components/Contact';
export default function ConnectedContact(props) {
  const {
    values,
    onChangeMethods,
    formValidation,
    loading,
  } = useContactConnectors();
  const history = useHistory();

  const navigateToThankYouScreen = () => {
    history.push(ROUTES.THANK_YOU);
  };

  const sendForm = async () => {
    try {
      await formValidation();
      await sendEmail(values);
      navigateToThankYouScreen();
    } catch (err) {
      if (err.name !== validationErrorsType.CONTACT) {
        throw err;
      }
    } finally {
      loading(false);
    }
  };

  return (
    <Contact
      {...values}
      {...props}
      {...onChangeMethods}
      onSendForm={() => sendForm()}
    />
  );
}
