import React from 'react';
import { useSelectContext } from 'Context/SelectLanguageContext';
import Homepage from 'Translations/home.json';
import styles from './ProjectBanner.module.scss';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AnimationWrapper from '../AnimationWrapper';
import { fadeInUp, stagger } from '../../../Animations';

export default function ProjectBanner(props) {
  const { t } = useSelectContext();
  const {
    logoSrc,
    title,
    description,
    illustrationSrc,
    backgroundColor,
    slug,
  } = props;

  return (
    <AnimationWrapper variants={stagger}>
      <div
        className={styles.container}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className={styles.inner_container}>
          <motion.div variants={fadeInUp} className={styles.banner_info}>
            <img className={styles.banner_logo} src={logoSrc} alt="logo" />
            <h2 className={styles.banner_title}>{title}</h2>
            <p className={styles.banner_description}>{description}</p>
            <Link
              className={styles.banner_button}
              to={`/project-detail/${slug}`}
            >
              {t(Homepage.HowWeDidIt)}
            </Link>
          </motion.div>
          <motion.div
            variants={fadeInUp}
            className={styles.banner_illustration}
          >
            <img
              className={styles.banner_illustration_image}
              src={illustrationSrc}
              alt="project illustration"
            />
          </motion.div>
        </div>
      </div>
    </AnimationWrapper>
  );
}
