import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'Common/Router';
import ContactTL from 'Translations/contact.json';
import Button from 'Common/Components/Button';
import { useSelectContext } from 'Context/SelectLanguageContext';
import styles from './Thankyou.module.scss';

export default function ThankYou() {
  const { t } = useSelectContext();
  const history = useHistory();
  return (
    <div style={{ height: '100vh', width: '100%vw' }}>
      <div className={styles.thankyou__internal_container}>
        <h1 className={styles.title}>{t(ContactTL.GoBackMessage)}</h1>
        <div className={styles.thankyou__button_container}>
          <Button
            onClick={() => history.push(ROUTES.HOME)}
            label={t(ContactTL.GoBackToHome)}
          />
        </div>
      </div>
    </div>
  );
}
