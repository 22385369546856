import { useSelectContext } from 'Context/SelectLanguageContext';
import styles from './LanguageSelectionWidget.module.scss';

export default function LanguageSelectionWidget() {
  let { languages, setCurrentLanguage, currentLanguage } = useSelectContext();

  function onChange(event) {
    setCurrentLanguage(event.target.value);
  }

  return (
    <div>
      <select
        className={styles.languageWidget}
        onChange={onChange}
        value={currentLanguage}
      >
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  );
}
