import React from 'react';
import styles from './Testimonials.module.scss';

export default function Testimonials(props) {
  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <p className={styles.testimonial_text}>"{props.testimonial}"</p>
        <div className={styles.testimonial_client_wrapper}>
          <p className={styles.testimonial_client_name}>{props.clientName}:</p>
          <p className={styles.testimonial_client_company}>
            {props.clientCompany}
          </p>
        </div>
      </div>
    </div>
  );
}
