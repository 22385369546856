import React from 'react';
import { motion } from 'framer-motion';
import styles from './SectionTitle.module.scss';
import SectionTitle from '../SectionTitle';

export default function SectionTitleWithDescription({
  title,
  description,
  additionalStyle,
  variants,
}) {
  return (
    <motion.div
      variants={variants}
      style={additionalStyle}
      className={styles.sectionTitleWithDescription}
    >
      <SectionTitle marginTop={128} text={title} />
      <div className={styles.sectionTitleWithDescription__description}>
        {description}
      </div>
    </motion.div>
  );
}
