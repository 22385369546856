import React, { useRef } from 'react';
import LandingHeader from 'Common/Components/LandingHeader';
import HeaderProjectList from 'Common/Components/HeaderProjectList';
import Testimonials from 'Common/Components/Testimonials';
import MinWidthRender from 'Common/Components/MinWidthRender';
import ProjectBanner from 'Common/Components/ProjectBanner';
import Contact from 'Common/ConnectedComponents/Contact';
import { useQuery } from 'graphql-hooks';
import { useSelectContext } from 'Context/SelectLanguageContext';
import { HOMEPAGE_QUERY } from 'GraphqlQueries';

export default function Home() {
  const { t } = useSelectContext();
  const { data } = useQuery(HOMEPAGE_QUERY);
  const contactRef = useRef();

  const scrollToContact = () => contactRef.current.scrollIntoView();

  return (
    <div>
      <LandingHeader onButtonPress={scrollToContact} />
      <MinWidthRender minWidth={700}>
        <HeaderProjectList />
      </MinWidthRender>
      {data &&
        data.allProjects.map((project) => {
          return (
            <div key={project.infosProjectName}>
              <ProjectBanner
                logoSrc={project.descriptionLogo.url}
                backgroundColor={project.infosProjectColor.hex}
                title={t(project._allDescriptionTitleLocales)}
                description={t(project._allDescriptionTextLocales)}
                illustrationSrc={project.descriptionImage.url}
                slug={project.infosSlug}
                onClick={() => {}}
              />
              <Testimonials
                clientName={project.homeTestimonialName}
                clientCompany={project.homeTestimonialCompany}
                testimonial={t(project._allHomeTestimonialQuoteLocales)}
              />
            </div>
          );
        })}
      <Contact propsRef={contactRef} />
    </div>
  );
}
