import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { SelectLanguageProvider } from './Context/SelectLanguageContext';

const client = new GraphQLClient({
  url: 'https://graphql.datocms.com/',
  headers: {
    Authorization: process.env.REACT_APP_DATO_CMS_AUTHORIZATION,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <SelectLanguageProvider>
      <ClientContext.Provider value={client}>
        <App />
      </ClientContext.Provider>
    </SelectLanguageProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
