import React from 'react';
import _ from 'lodash';
import { useQuery } from 'graphql-hooks';
import { useParams } from 'react-router-dom';
import { PROJECT_DETAIL_QUERY } from 'GraphqlQueries';
import ProjectTl from 'Translations/project.json';
import { useSelectContext } from 'Context/SelectLanguageContext';
import Numbers from 'Common/Components/Numbers';
import ProjectDetailsHeader from 'Common/Components/ProjectDetailsHeader';
import SectionTitleWithDescription from 'Common/Components/SectionTitleWithDescription';
import SectionDevices from 'Common/Components/SectionDevices';
import ProjectDetailCarousel from 'Common/Components/ProjectDetailCarousel';
import Contact from 'Common/Components/Contact';
import styles from './ProjectDetails.module.scss';
import AnimationWrapper from '../../Common/Components/AnimationWrapper';
import { fadeInUp, stagger, staggerDelayed } from '../../Animations';

export default function ProjectDetails() {
  let { name } = useParams();
  let { t } = useSelectContext();

  const { data, loading } = useQuery(PROJECT_DETAIL_QUERY, {
    variables: {
      projectName: name,
    },
  });

  if (loading) {
    return <div></div>;
  }
  if (data) {
    return (
      <div className={styles.projectDetails_container}>
        <ProjectDetailsHeader
          color={data.project.infosProjectColor.hex}
          logo={data.project.detailHeaderLogo.url}
          quote={t(data.project._allDetailHeaderQuoteLocales)}
          cover={data.project.detailHeaderCover.url}
          title={t(data.project._allDetailHeaderTitleLocales)}
          text={t(data.project._allDetailHeaderTextLocales)}
        />
        <AnimationWrapper variants={stagger}>
          <SectionTitleWithDescription
            variants={fadeInUp}
            title={t(ProjectTl.TheChallenge)}
            description={t(data.project._allDetailBodyChallengeLocales)}
          />
        </AnimationWrapper>
        <AnimationWrapper variants={stagger}>
          {_.get(
            data.project._allDetailBodyNumbersLocales,
            '[0].value.length',
            0
          ) > 0 && (
            <Numbers
              variants={staggerDelayed}
              stats={t(data.project._allDetailBodyNumbersLocales)}
            />
          )}
        </AnimationWrapper>
        {data.project.detailBodySlider && (
          <ProjectDetailCarousel images={data.project.detailBodySlider} />
        )}
        <SectionDevices deviceImages={data.project.detailBodyDevices} />
        <AnimationWrapper variants={stagger}>
          <SectionTitleWithDescription
            variants={fadeInUp}
            additionalStyle={{ marginBottom: 80 }}
            title={t(ProjectTl.OzSolution)}
            description={t(data.project._allDetailBodySolutionLocales)}
          />
        </AnimationWrapper>
        <Contact />
      </div>
    );
  }
}
