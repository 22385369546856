import React from 'react';
import logo from 'Assets/Logo.png';
import miniLogo from 'Assets/MiniLogo.png';
import styles from './Logo.module.scss';
import { useViewport } from 'Hooks/useViewport';
import { Link } from 'react-router-dom';

export default function Logo() {
  const { width } = useViewport();

  if (width > 450) {
    return (
      <Link className={styles.logo__wrapper} to={'/'}>
        <img className={styles.logo} src={logo} alt="logo"></img>
      </Link>
    );
  }
  return (
    <Link className={styles.mini_logo__wrapper} to={'/'}>
      <img className={styles.miniLogo} src={miniLogo} alt="logo"></img>
    </Link>
  );
}
