export const HOMEPAGE_QUERY = `query HomePage{
    allProjects {
    infosProjectName
    infosSlug
    infosProjectColor {
      hex
    }
    descriptionLogo {
      url
    }
    _allDescriptionTitleLocales(locale: en) {
      value
      locale
    }
    _allDescriptionTextLocales(locale: en) {
      value
      locale
    }
    descriptionImage {
      url
    }
    _allHomeTestimonialQuoteLocales(locale: en){
      value,
      locale
    }
    homeTestimonialName
    homeTestimonialCompany
  }
}`;

export const PROJECT_DETAIL_QUERY = `query HomePage($projectName: String) {
      project(filter: {infosSlug: {eq: $projectName}}) {
            _allDetailHeaderQuoteLocales {
      locale
      value
    }
    infosProjectColor {
      hex
    }
    detailHeaderLogo{
      url
    }
    detailHeaderCover {
      url
    }
    detailBodySlider {
      url
    }
    _allDetailHeaderQuoteLocales{
      locale
      value
    }
    _allDetailHeaderTitleLocales {
      locale
      value
    }
    _allDetailHeaderTextLocales {
      locale
      value
    }
    _allDetailBodyChallengeLocales{
      locale
      value
    }
    _allDetailBodyNumbersLocales{
      locale
      value {
        digit
        text
      }
    }
    detailBodyDevices{
      url
    }
    _allDetailBodySolutionLocales{
      locale
      value
    }
  }
 }`;
