import React from 'react';
import styles from './Button.module.scss';
import ClipLoader from 'react-spinners/ClipLoader';
export default function Button(props) {
  const clickButton = () => {
    if (props.loading) return;
    props.onClick();
  };

  return (
    <div onClick={clickButton} className={styles.button}>
      {props.loading && <ClipLoader size={25} color={'#FFF'} />}
      {!props.loading && <p className={styles.button_label}>{props.label}</p>}
    </div>
  );
}
