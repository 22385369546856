import { createContext } from 'react';
import { useSelectLanguage } from '../Hooks/useSelectLanguage';
import { useContext } from 'react';

export const SelectLanguageContext = createContext();

export const SelectLanguageProvider = ({ children }) => (
  <SelectLanguageContext.Provider value={useSelectLanguage()}>
    {children}
  </SelectLanguageContext.Provider>
);
export const useSelectContext = () => useContext(SelectLanguageContext);
