import { useViewport } from 'Hooks/useViewport';

export default function MinWidthRender(props) {
  const { width } = useViewport();
  const { minWidth, children } = props;

  if (minWidth < width) {
    return children;
  } else return null;
}
