import _ from 'lodash';
import styles from './Menu.module.scss';
import { Link, useLocation } from 'react-router-dom';
import MenuTl from 'Translations/menu.json';
import LanguageSelectionWidget from '../LanguageSelectionWidget';
import { useSelectContext } from 'Context/SelectLanguageContext';
import { ROUTES } from 'Common/Router';
export default function Menu(props) {
  const { t } = useSelectContext();
  const location = useLocation();
  const LINK_MAP_LIST = [
    {
      path: ROUTES.ABOUT,
      label: t(MenuTl.About),
    },
    {
      path: ROUTES.HOME,
      label: t(MenuTl.Home),
    },
  ];

  const filteredLinks = _.filter(
    LINK_MAP_LIST,
    (link) => link.path !== location.pathname
  );
  return (
    <div className={styles.menu_container}>
      {_.get(props, 'showLinks', true) &&
        filteredLinks.map((link, index) => (
          <Link key={index} className={styles.menu_link} to={link.path}>
            {link.label}
          </Link>
        ))}
      <LanguageSelectionWidget />
    </div>
  );
}
