import React from 'react';
import Logo from '../Logo';
import SF from 'Assets/SF.png';
import StartLab from 'Assets/StartLab.png';
import HomeTl from 'Translations/home.json';
import { useSelectContext } from 'Context/SelectLanguageContext';
import styles from './LandingHeader.module.scss';
import Menu from 'Common/Components/Menu';
import AnimationWrapper from '../AnimationWrapper';
import { motion } from 'framer-motion';
import { fadeInUp, stagger } from '../../../Animations';

export default function LandingHeader(props) {
  const { t } = useSelectContext();

  return (
    <AnimationWrapper variants={stagger}>
      <div className={styles.wrapper}>
        {HomeTl && (
          <div>
            <div className={styles.ozLogo_wrapper}>
              <Logo />
            </div>
            <Menu />
            <motion.div variants={fadeInUp} className={styles.container}>
              <h1 className={styles.title}>{'Visionnary'}</h1>
              <br />
              <div className={styles.yellow_title_wrapper}>
                <h1 className={`${styles.title} ${styles.yellow}`}>{'Apps'}</h1>
                <h1 className={styles.title}>{'Makers.'}</h1>
              </div>
              <p className={styles.paragraph}>{t(HomeTl.YouHaveDream)}</p>
              <div onClick={props.onButtonPress} className={styles.button}>
                <p className={styles.button_label}>
                  {t(HomeTl.LetsStartAProject)}
                </p>
              </div>
            </motion.div>

            <div className={styles.incubators}>
              <div className={styles.separator} />
              <div className={styles.incubators_section}>
                <motion.p
                  variants={fadeInUp}
                  className={styles.incubators_label}
                >
                  {t(HomeTl.IncubatorsWeHaveWorked)}
                </motion.p>
                <div className={styles.logo_wrapper}>
                  <motion.img
                    variants={fadeInUp}
                    className={styles.incubator_logo}
                    src={StartLab}
                    alt="startlab logo"
                  />
                  <motion.img
                    variants={fadeInUp}
                    className={styles.incubator_logo}
                    src={SF}
                    alt="SF logo"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AnimationWrapper>
  );
}
