export const validationErrorsType = {
  CONTACT: 'contact',
};

export class ContactFormValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = validationErrorsType.CONTACT;
  }
}
