import React from 'react';
import { motion } from 'framer-motion';
import styles from './Number.module.scss';
import { fadeInUp } from '../../../../Animations';

export default function Index(props) {
  return (
    <motion.div
      variants={fadeInUp}
      className={styles.projectDetails__numbers__number}
    >
      <h3 className={styles.projectDetails__numbers__number__digit}>
        {props.digit}
      </h3>
      <div className={styles.projectDetails__numbers__number__subText}>
        {props.subText}
      </div>
    </motion.div>
  );
}
