import React from 'react';
import styles from './Input.module.scss';
export default function Input(props) {
  function isInvalid() {
    if (props.error) {
      return styles.input_error;
    }
    return '';
  }
  return (
    <div className={styles.input_innerwrapper}>
      <input
        placeholder={props.placeholder}
        className={`${styles.input} ${isInvalid()}`}
        type={props.type || 'text'}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <p className={styles.input_error}>{props.error}</p>
    </div>
  );
}
