import React from 'react';
import { motion } from 'framer-motion';
import styles from './SectionDevices.module.scss';
import AnimationWrapper from '../AnimationWrapper';
import { fadeIn, stagger } from '../../../Animations';

export default function SectionDevices({ deviceImages }) {
  return (
    <AnimationWrapper variants={stagger}>
      <div className={styles.section_container}>
        <div className={styles.section_inner}>
          {deviceImages.map((image, index) => (
            <motion.img
              variants={fadeIn}
              className={styles.section_device}
              key={index}
              src={image.url}
              alt="application layout"
            />
          ))}
        </div>
      </div>
    </AnimationWrapper>
  );
}
