import React from 'react';
import CarouselNextButton from 'Assets/CarouselArrow.png';
import styles from './CarouselNextButton.module.scss';

export function LeftButton(props) {
  //props hasPrev, label and clickHandler are coming from react-responsive-carousel
  if (props.hasPrev) {
    return (
      <div
        className={`${styles.carousel_button_container} ${styles.left}`}
        onClick={props.clickHandler}
      >
        <img src={CarouselNextButton} alt={props.label} />
      </div>
    );
  }
  return <div></div>;
}

export function RightButton(props) {
  if (props.hasNext) {
    return (
      <div
        className={`${styles.carousel_button_container} ${styles.right}`}
        onClick={props.clickHandler}
      >
        <img src={CarouselNextButton} alt={props.label} />
      </div>
    );
  }
  return <div></div>;
}
