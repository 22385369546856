import React, { useRef } from 'react';
import HeaderAbout from 'Common/Components/HeaderAbout';
import SectionTitleWithDescription from 'Common/Components/SectionTitleWithDescription';
import SectionTitle from 'Common/Components/SectionTitle';
import QnASectionDescription from 'Common/Components/QnASectionDescription';
import Contact from 'Common/Components/Contact';
import AboutTl from 'Translations/about.json';
import { useSelectContext } from 'Context/SelectLanguageContext';
import styles from './About.module.scss';
import AnimationWrapper from '../../Common/Components/AnimationWrapper';
import { fadeInUp, stagger } from '../../Animations';

export default function About() {
  const { t } = useSelectContext();
  const contactRef = useRef();
  const scrollToContact = () => contactRef.current.scrollIntoView();

  return (
    <>
      <HeaderAbout onButtonPress={scrollToContact} />
      <AnimationWrapper variants={stagger}>
        <SectionTitleWithDescription
          variants={fadeInUp}
          title={t(AboutTl.OurWay)}
          description={t(AboutTl.OurWayDescription)}
        />
        <SectionTitleWithDescription
          variants={fadeInUp}
          title={t(AboutTl.TheTech)}
          description={t(AboutTl.TheTechDescription)}
        />
      </AnimationWrapper>
      <AnimationWrapper variants={stagger}>
        <div className={styles.about__qna}>
          <SectionTitle text={t(AboutTl.NeedSomeHelp)} />
          <QnASectionDescription
            variants={fadeInUp}
            title={t(AboutTl.FromZero)}
            description={t(AboutTl.FromZeroDescription)}
          />
          <QnASectionDescription
            variants={fadeInUp}
            title={t(AboutTl.PoweringUp)}
            description={t(AboutTl.PoweringUpDescription)}
          />
        </div>
      </AnimationWrapper>
      <Contact propsRef={contactRef} />
    </>
  );
}
